import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Container from '../Container'

const BlueHeaderWrapper = styled.div`
  ${tw`bg-light-blue py-20`}
`

const BlueHeaderTitle = styled.h1`
  ${tw`font-montserrat text-black text-4xl font-light m-0 mb-1`}
  line-height: 46px;
`

const BlueHeader = (props) => {
  const { title } = props;
  return (
    <BlueHeaderWrapper>
      <Container width={830}>
        <BlueHeaderTitle dangerouslySetInnerHTML={{ __html: title }} />
      </Container>
    </BlueHeaderWrapper>
  )
}

BlueHeader.propTypes = {
  title: PropTypes.string.isRequired
}

BlueHeader.defaultProps = {}

export default BlueHeader
